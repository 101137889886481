<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <addHeader
          :title="$t('accountsDeposits.form.CreateADepositProcess')"
          :description="$t('accountsDeposits.form.FromHereYouCanCreateANewDepositProcess')"
          v-if="$route.name == 'accountsDepositsCreate'"
        />
        <addHeader
          :title="$t('accountsDeposits.form.ModifyingTheFilingProcess')"
          :description="$t('accountsDeposits.form.FromHereYouCanModifyTheFilingData')"
          v-if="$route.name == 'accountsDepositsEdit'"
        />

        <div class="mb-4 row">
          <connectionInput
            :title="$t('accountsDeposits.form.NumberingTheBatch')"
            :group="$t('accountsDeposits.form.Groups')"
            v-model="item.invoice_group"
            :hasErorr="errors.invoice_group"
            :disabled="
              $user.admin || $user.role.accounts_deposits_edit_item
                ? false
                : true
            "
            :error="$t('accountsDeposits.form.thisFieldIsRequired')"
            :values="$database.invoiceGroups"
            v-if="$route.name == 'accountsDepositsCreate'"
          />
          <formInput
            :title="$t('accountsDeposits.form.OperationCode')"
            v-model="item.code"
            :disabled="true"
            maxlength="255"
            v-if="$route.name == 'accountsDepositsEdit'"
          />
          <subFileInput :title="$t('invoices.form.invoiceFile')"
                     v-model="item.file"
                     :file="item.file"
                     :path="'invoices'"
                     :hasErorr="errors.file"
                     :error="$t('allerts.ChooseASuitableFile')"/>
          <formInput
          :title="$t('salesPayments.form.reference')"
          v-model="item.refrance"
          maxlength="255" />

          <accountNameInput
            :disabled="$route.name == 'accountsDepositsEdit' ? true : false"
          />
          
          <monyInput
            :title="$t('accountsDeposits.form.balance')"
            v-model="item.account.balance"
            disabled="true"
          />

          <div class="form-group row" :class="'col-md-6'">
            <label :class="'col-md-4'">{{$t('accountsDeposits.form.TheHistoryOfTheOperation')}}</label>
            <div :class="'col-md-7'">
              <div class="icon-input w-100 to-left">
                <span><i class="far fa-calendar"></i></span>
                <VueCtkDateTimePicker
                  :label="$t('accountsDeposits.form.ChooseTimeAndDate')"
                  :button-now-translation="$t('accountsDeposits.form.Now')"
                  v-model="item.date"
                  :disabled="
                    $user.admin ||
                    $user.role.accounts_deposits_edit_item
                      ? false
                      : true
                  "
                  format="YYYY-MM-DD hh:mm a"
                  color="#631263"
                  button-color="#631263"
                />
              </div>
            </div>
          </div>

          <selectInput
            :title="$t('invoices.form.Situation')"
            v-model="item.status"
            :values="[
              { name: $t('invoices.draft'), value: 0 },
              { name: $t('invoices.approved'), value: 1 }
            ]"
          />

          <dvider
            :title="$t('accountsDeposits.form.accountsInformation')"
            :description="$t('accountsDeposits.form.AccountInformationDetails')"
          />

          <monyInput
            :title="$t('accountsDeposits.form.cash')"
            v-model="item.cost"
            :hasErorr="errors.cost"
            :error="$t('allerts.theAmountReceivedMustBeGreaterThan')"
          />

          <searchGroupInput
            :type="'safe'"
            :values="$database.safes"
            :inputs="[{ 'show': 'name'}]"
          />
          <searchGroupInput
            :type="'paymentMethod'"
            :values="$database.paymentMethods"
            :inputs="[{ 'show': 'name'}]"
          />

          <div class="col-6"></div>

          <dvider
            :title="$t('accountsDeposits.form.notes')"
            :description="$t('accountsDeposits.form.PaymentNotes')"
          />

          <formTextarea
            :title="$t('accountsDeposits.form.thatAbout')"
            v-model="item.description"
          />
          <formTextarea :title="$t('accountsDeposits.form.note')" v-model="item.notes"/>
          <hr class="mt-5 mb-5 col-12" />
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'accountsDepositsCreate' && !$parent.stopEdit"
          >
            {{$t('accountsDeposits.form.AddAnOperation')}}
          </a>
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'accountsDepositsEdit' && !$parent.stopEdit"
          >
            {{$t('accountsDeposits.form.ProcessModification')}}
          </a>
          <loading v-if="$parent.stopEdit"/>
        </div>
      </div>
    </div>
    <addPaymentMethod v-if="model == 'addPaymentMethod'"/>
    <addPaymentFee v-if="model == 'addPaymentFee'"/>
  </div>
</template>
<script>
import axios from "axios";

import formInput from "../elements/add/form/formInput.vue";
import formTextarea from "../elements/add/form/formTextarea.vue";
import connectionInput from "../elements/add/form/connectionInput.vue";
import monyInput from "../elements/add/form/monyInput.vue";
import selectInput from "../elements/add/form/selectInput.vue";
import checkInput from "../elements/add/form/checkInput.vue";
import dvider from "../elements/add/dvider.vue";
import addHeader from "../elements/add/header.vue";
import loading from "../elements/add/loading.vue";
import searchGroupInput from "../elements/add/form/searchGroupInput.vue";
import subFileInput from "../elements/add/form/subFileInput.vue";

import addPaymentMethod from "../elements/popupForms/addPaymentMethod.vue";
import addPaymentFee from "../elements/popupForms/addPaymentFee.vue";

import accountNameInput from "../elements/add/form/search/accountNameInput.vue";

import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

export default {
  data() {
    return {
      path: "/accountsDeposits",
      item: {
        invoice_group: this.$option.accounts_deposits_invoice_group_id,
        account_id: 0,
        account: {
          name: "",
          mobile: "",
          address1: "",
          balance: 0,
        },
        safe:{},
        paymentMethod:{},
        payment_method_id: 0,
        safe_id: 0,
        date: "",
        status: 1,
      },
      model: false,
      errors: {
        invoice_group: false,
        name: false,
        cost: false,
        safe_id: false,
      },
      print: this.$route.name == "accountsDepositsCreate" ? true : false,
    };
  },
  mounted() {
    this.$updateDatabase([
      "invoiceGroups",
      "accounts",
      "projects",
      "projectItems",
      "safes",
      "paymentMethods",
    ]);

    if (this.$route.name == "accountsDepositsEdit") {
      this.$parent.stopEdit = true;
      this.getItem();
    } else {
      if (this.$database.safes.length == 1) {
        this.item.safe_id = this.$database.safes[0].id;
      }

      var params = this.$getParams()
      if (params.account_id) {
        var account = this.$database.accounts.find(
          (el) => el.id == params.account_id
        )
        this.item.account_id = params.account_id;
        this.item.account = account;
      }
    }
    if (this.$route.name == "accountsDepositsCreate") {
      this.item.date = this.$nowDate()
    }

    var elm = this;
      window.addEventListener("keydown", function (e) {
        if (e.key == "F1" || e.key == "Meta") {
          e.preventDefault();
          elm.saveItem();
        }
    })
  },
  methods: {
    getItem() {
      axios
        .get(this.$linkGnirator(this.path + "/" + this.$route.params.id))
        .then((response) => {
          this.item = response.data;
          this.$parent.stopEdit = false;
          this.item.date = this.$dateFormat(this.item.date, true)
        });
    },
    saveItem() {
      var error = 0;
      if (
        (typeof this.item.account_id === "undefined" ||
          this.item.account_id == "")
      ) {
        error = 1;
        this.errors.name = true;
      }
      if (
        typeof this.item.cost === "undefined" ||
        this.item.cost == "" ||
        this.item.cost == 0
      ) {
        error = 1;
        this.errors.cost = true;
      }
      if (
        (typeof this.item.invoice_group === "undefined" ||
          this.item.invoice_group == 0 ||
          this.item.invoice_group == "" ||
          this.item.invoice_group == null) &&
        this.$route.name == "accountsDepositsCreate"
      ) {
        error = 1;
        this.errors.invoice_group = true;
      }
      if (this.$route.name == "accountsDepositsCreate") {
        if (
          typeof this.item.safe_id === "undefined" ||
          this.item.safe_id == "" ||
          this.item.safe_id == 0
        ) {
          error = 1;
          this.errors.safe_id = true;
        }
      }
      if (error == 0) {
        this.item.date = this.$fixDate(this.item.date);
        if (this.$route.name == "accountsDepositsCreate") {
          this.$parent.aletText = this.$t('allerts.accountsDepositsuccessfullyAdde');
          this.$parent.alertType = "success";
          this.$addToLocalDatabase(this.item, this.path.substring(1));
         
          this.item.old_balance = this.item.account.balance;
          this.item.new_balance = parseFloat(this.item.old_balance) + parseFloat(this.item.cost);         
          // update account balance
          var account = this.$database.accounts.find((el) => el.id == this.item.account_id);
          if (account) {
            account.balance = this.item.new_balance;
          }
          // update safe balance
          var safe = this.$database.safes.find((el) => el.id == this.item.safe_id);
          if (safe) {
            safe.balance = parseFloat(safe.balance) + parseFloat(this.item.cost);
          }

          localStorage.database = JSON.stringify(this.$database);
          this.$router.go(-1);
          this.$parent.stopEdit = false;
          if (this.$option.print_after) {
            var today = new Date();
            var date =
              today.getFullYear() +
              "/" +
              (today.getMonth() + 1) +
              "/" +
              today.getDate();
            var time = today.getHours() + ":" + today.getMinutes();

            var hours = today.getHours();
            var minutes = today.getMinutes();
            var ampm = hours >= 12 ? "pm" : "am";
            hours = hours % 12;
            hours = hours ? hours : 12;
            minutes = minutes < 10 ? "0" + minutes : minutes;
            var time = hours + ":" + minutes + " " + ampm;

            this.item.date = date;
            this.item.time = time;

            this.item.created_at = new Date().toISOString("ar-EG", {
              timeZone: "Africa/Cairo",
            });
            this.$parent.printedSalesPayment = this.item;
          }
        } else if (this.$route.name == "accountsDepositsEdit") {
          this.item._method = "PUT";
          this.$parent.stopEdit = true;
          axios
            .post(this.$linkGnirator(this.path + "/" + this.item.id), this.item)
            .then((response) => {
              if (response.data == "safe error") {
                this.$parent.aletText = "الموظف غير مربوط بخزينة";
                this.$parent.alertType = "danger";
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              } else {
                this.$parent.aletText =
                  this.$t('allerts.salesPaymentHasBeenModifiedSuccessfully');
                this.$parent.alertType = "success";
                this.$router.go(-1);
                this.$parent.stopEdit = false;
                return response;
              }
            });
        }
      } else {
        this.$parent.aletText = this.$t('allerts.pleaseMakeSureOfTheInput');
        this.$parent.alertType = "danger";
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
  },
  watch: {
    selectedPaymentFees: function (val) {
      this.item.payment_fee_id = val.map((el) => el.id);
    },
  },

  components: {
    formInput,
    dvider,
    addHeader,
    monyInput,
    selectInput,
    formTextarea,
    connectionInput,
    checkInput,
    addPaymentMethod,
    addPaymentFee,
    loading,
    searchGroupInput,

    accountNameInput,

    VueCtkDateTimePicker,
    subFileInput,
  },
};
</script>
